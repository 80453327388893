import React from "react";
import cx from "classnames";
import "./Footer.css";
import logoImg from "img/ic_mmy_footer.svg";
import twitterIcon from "img/ic_twitter.svg";
import discordIcon from "img/ic_discord.svg";
import telegramIcon from "img/ic_telegram.svg";
import mediumIcon from "img/ic_medium.svg";
import { isHomeSite } from "lib/legacy";

const socialLinks = [
  { link: "https://twitter.com/mummyftm", name: "Twitter", icon: twitterIcon },
  { link: "https://medium.com/@mummyftm", name: "Medium", icon: mediumIcon },
  { link: "https://t.me/mummyftm", name: "Telegram", icon: telegramIcon },
  { link: "https://discord.gg/xqSJaw8sUB", name: "Discord", icon: discordIcon },
];

export default function Footer({ showRedirectModal, redirectPopupTimestamp }) {
  const isHome = isHomeSite();

  return (
    <div className="Footer">
      <div className={cx("Footer-wrapper", { home: isHome })}>
        <div className="Footer-logo">
          <img src={logoImg} alt="MetaMask" />
        </div>
        <div className="Footer-social-link-block">
          {socialLinks.map((platform) => {
            return (
              <a
                key={platform.name}
                className="App-social-link"
                href={platform.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={platform.icon} alt={platform.name} />
              </a>
            );
          })}
        </div>
        <div className="Footer-links"><a href="mailto:contact@mummy.finance" className="Footer-link">contact@mummy.finance</a></div>
      </div>
    </div>
  );
}
