import { ARBITRUM, MUMBAI, FANTOM, MAINNET } from "./chains";

export const MMY_STATS_API_URL = "https://stats.mummy.finance/api";
export const COINGECKO_API_URL = "https://api.coingecko.com/api/v3/coins";

const BACKEND_URLS = {
  default: "https://api.mummy.finance/api",

  [MAINNET]: "https://gambit-server-staging.uc.r.appspot.com",
  [MUMBAI]: "https://api.mummy.finance/api",
  [ARBITRUM]: "https://gmx-server-mainnet.uw.r.appspot.com",
  [FANTOM]: "https://api.mummy.finance/api",
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  // return "http://localhost:3000/api"

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
