import React from "react";
import { useWeb3React } from "@web3-react/core";
import { Trans } from "@lingui/macro";
import useSWR from "swr";

import Footer from "components/Footer/Footer";
import "./Poll.css";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import linkIcon from "img/link.svg";
import mmUsdIcon from "img/mm-usd.svg";
import pollBackImage from "img/img_right.svg";
import { getContract } from "config/contracts";
import { CHAIN_ID } from "config/chains";
import { useTotalMmyInLiquidity, useMmyPrice } from "domain/legacy";
import { USD_DECIMALS, USDC_DECIMALS, MMY_DECIMALS, shortenAddress } from "lib/legacy";
import { formatAmount, expandDecimals } from "lib/numbers";
import UniswapV2 from "abis/UniswapV2.json";
import { contractFetcher } from "lib/contracts";
import { getTokenBySymbol } from "config/tokens";
import Vault from "abis/Vault.json";

const lpTokenAddress = getContract(CHAIN_ID, "UniswapMmyUsdcPool");
const shortLpTokenAddress = shortenAddress(lpTokenAddress, 13);
const lpTokenUrl = "https://ftmscan.com/address/" + lpTokenAddress;

const timeLockAddress = getContract(CHAIN_ID, "TimeLock");
const shortTimeLockAddress = shortenAddress(timeLockAddress, 13);
const timeLockUrl = "https://ftmscan.com/address/" + timeLockAddress;

const liquidityLockAddress = getContract(CHAIN_ID, "LiquidityLock");
const shortLiquidityLockAddress = shortenAddress(liquidityLockAddress, 13);
const liquidityLockUrl = "https://ftmscan.com/address/" + liquidityLockAddress;

export default function Poll() {
  const { active } = useWeb3React();

  let { total: totalMmyInLiquidity } = useTotalMmyInLiquidity();

  const { mmyPrice } = useMmyPrice(
    CHAIN_ID,
    undefined,
    active
  );

  let mmyLiquidityCap
  if (mmyPrice && totalMmyInLiquidity)
    mmyLiquidityCap = mmyPrice.mul(totalMmyInLiquidity).div(expandDecimals(1, MMY_DECIMALS));

  // Get USDC price and reserve in pool
  const poolAddress = getContract(CHAIN_ID, "UniswapMmyUsdcPool");

  const { data } = useSWR(["UniswapMmyUsdcPool", CHAIN_ID, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });

  const { _reserve1: usdcReserve } = data || {};

  const vaultAddress = getContract(CHAIN_ID, "Vault");
  const usdcAddress = getTokenBySymbol(CHAIN_ID, "USDC").address;
  const { data: usdcPrice } = useSWR(
    [`StakeV2:usdcPrice`, CHAIN_ID, vaultAddress, "getMinPrice", usdcAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  let usdcLiquidityCap;
  if (usdcPrice && usdcReserve)
    usdcLiquidityCap = usdcPrice.mul(usdcReserve).div(expandDecimals(1, USDC_DECIMALS));

  let totalLiquidityCap;
  if (mmyLiquidityCap && usdcLiquidityCap)
    totalLiquidityCap = mmyLiquidityCap.add(usdcLiquidityCap);

  return (
    <SEO title={getPageTitle("Proof of Locked Liquidity")}>
      <div className="page-layout Poll-layout">
        <div className="Poll-sublayout">
          <div className="Poll-container">
            <div className="title">
              <Trans>Proof of Locked Liquidity</Trans>
            </div>
            <div>
              <Trans>Liquidity is locked by renouncing the ownership of liquidity pool (LP) tokens for a fixed time period, by sending them to a time-lock smart contract.</Trans>
            </div>
            <div className="link-wrapper">
              <div>
                <div className="label">LP token</div>
                <div className="info">{shortLpTokenAddress}</div>
                <a href={lpTokenUrl} target="_blank" rel="noreferrer">
                  <img src={linkIcon} alt="link" />
                </a>
              </div>
              <div>
                <div className="label">Time lock</div>
                <div className="info"> {shortTimeLockAddress}</div>
                <a href={timeLockUrl} target="_blank" rel="noreferrer">
                  <img src={linkIcon} alt="link" />
                </a>
              </div>
              <div>
                <div className="label">Liquidity lock</div>
                <div className="info"> {shortLiquidityLockAddress}</div>
                <a href={liquidityLockUrl} target="_blank" rel="noreferrer">
                  <img src={linkIcon} alt="link" />
                </a>
              </div>
            </div>
            <div className="liquididy-wrap">
              <div className="liquidity-boxes">
                <div className="liquidity-box">
                  <div className="liquidity-label">Total liquidity locked</div>
                  <div className="liquidity-box-amount txt-green ">${formatAmount(totalLiquidityCap, USD_DECIMALS, 0, true)}</div>
                </div>
                <div className="liquidity-box">
                  <div className="liquidity-label">Lock until</div>
                  <div className="liquidity-box-amount">Jul 01, 2023</div>
                </div>
              </div>
              <div className="liquidity-pool-wrap">
                <div>
                  <div className="liquidity-label">Pair</div>
                  <div className="liquidity-pool-amount"> 
                  <img src={mmUsdIcon} alt="img" /> MMY/USDC
                  </div>
                </div>
                <div>
                  <div className="liquidity-label">Pooled MMY</div>
                  <div className="liquidity-pool-amount"> {formatAmount(totalMmyInLiquidity, MMY_DECIMALS, 0, true)}
                    <span className="to-usd">${formatAmount(mmyLiquidityCap, USD_DECIMALS, 0, true)}</span>
                  </div>
                </div>
                <div>
                  <div className="liquidity-label">Pooled USDC</div>
                  <div className="liquidity-pool-amount"> {formatAmount(usdcReserve, USDC_DECIMALS, 0, true)}
                    <span className="to-usd">${formatAmount(usdcLiquidityCap, USD_DECIMALS, 0, true)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Poll-back">
            <img src={pollBackImage} alt="bg" />
          </div> 
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
