import { ARBITRUM, MUMBAI, FANTOM, MAINNET, TESTNET } from "./chains";

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [MAINNET]: {
    // bsc mainnet
    Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    GMT: "0x99e92123eB77Bc8f999316f622e5222498438784",
    Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
    Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
    Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
    AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
    AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    OrderBook: "0x1111111111111111111111111111111111111111",
    OrderBookReader: "0x1111111111111111111111111111111111111111",
    MmyMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    XGMT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
    GMT_USDG_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
    XGMT_USDG_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
    GMT_USDG_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
    XGMT_USDG_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
    USDG_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
    XGMT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
    GMT_USDG_FARM_TRACKER_XGMT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
    XGMT_USDG_FARM_TRACKER_XGMT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
    AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
    AUTO_USDG_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
    AUTO_USDG_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
    AUTO_USDG_FARM_TRACKER_XGMT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
    AUTO_USDG_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
    GMT_MMY_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_MMY_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_MMY_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_MMY_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [TESTNET]: {
    // bsc testnet
    Vault: "0x1B183979a5cd95FAF392c8002dbF0D5A1C687D9a",
    Router: "0x10800f683aa564534497a5b67F45bE3556a955AB",
    Reader: "0x98D4742F1B6a821bae672Cd8721283b91996E454",
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    OrderBook: "0x9afD7B4f0b58d65F6b2978D3581383a06b2ac4e9",
    OrderBookReader: "0x0713562970D1A802Fa3FeB1D15F9809943982Ea9",
    MmyMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    NATIVE_TOKEN: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_MMY_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_MMY_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_MMY_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_MMY_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [MUMBAI]: {
    Vault: "0xb9FEAed010Cc37A37f0CDA6eAAEE561Bac7783A8",
    Router: "0xc25562D990C6EECEC2eF7C9ebBFC043A2d0CB02b",
    VaultReader: "0xC30568800cf7eC6B9BF1840765Cc4bdd7fF125Ef",
    Reader: "0xbe69733252F5847d3F396F44AeFA9e1bbD7122Fe",
    MlpManager: "0x1e3F35825d6481B26D3727D96731FB8c5252B7e2",
    RewardRouter: "0xE7853e02166d88E48FA91FC01235ea52707433ae",
    RewardReader: "0x3BC41AAdFecb8e01D646EEf3DA1cFC82CE3F697D",
    NATIVE_TOKEN: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    MLP: "0xcd65Ac83b9A518537aD286BEB5df5477ce17B0BF",
    MMY: "0x81bf4C940446f8a6ADa4096FC0Bde8FA39f8c50a",
    ES_MMY: "0x4E9B05aaDEB0cca6151C0E3b7324C50783892D8A",
    BN_MMY: "0x7f67061734b78e2ab31f730C72B31EdCA38D7e25",
    USDG: "0x8Bf45Af7cBF488a5f47Ee42E55427E4f4B3F3042",
    ES_MMY_IOU: "0x0000000000000000000000000000000000000000", // placeholder address

    StakedMmyTracker: "0x20457210e4931929Fd4A2eE59aBCdd9C2A31B3e5",
    BonusMmyTracker: "0x77F0A0feE1806654eaC6980E0bfF6764ee1c10A9",
    FeeMmyTracker: "0x40f057248e4BA492064995D8066aec6B754a67f3",
    StakedMlpTracker: "0x654F4CF82d3a0B878240262276aFB8eB99cAe216",
    FeeMlpTracker: "0x06158CB4Dce4eA438fee783B4f0b123bB03d639C",

    StakedMmyDistributor: "0x9dFc18c52F000f90ab10bB46760CdDDc071D8eB0",
    StakedMlpDistributor: "0xD236759DC98472593AA0cE065bA8574efe53C4eb",

    MmyVester: "0x829D8dB42a95D5B61E8e6A93697CE66c72905fe9",
    MlpVester: "0x51c10E399A5894D0A3Da96FA533bD5FC7b76B2d2",

    OrderBook: "0x90a924D9874f50f1271441627de62F99d29C4155",
    OrderExecutor: "0x6BFd6a9499aEcfE01Fd622F321aE612E2fbE71c3",
    OrderBookReader: "0xb77Db355E15DE4aD367D872c2d7066fe5729A1fB",

    PositionRouter: "0xDCc9E6f4b3A0B11645494cc106F1236CD9b59ff6",
    PositionManager: "0x8009457b27684D98B955022a8F2Cc0973396C7a1",

    UniswapMmyFtmPool: "",
    UniswapMmyUsdcPool: "",
    ReferralStorage: "0x5B8586816a7FFd6e05d18830E5b271274c69205c",
    ReferralReader: "0x248a2bbcD2098A28a4638d00266fAaCa330145D4",

    TimeLock: "0x86485d13C90efBD4Bbba5ea81BC5162537AbD3D7",
    LiquidityLock: "0x091d6D4B413e1C2294c491452d6d4bdAD33Fdc07",

    MummyClubNFT: "0x3b7b959bfd949f400c8dba2809d6b474715758e5",
    MummyClubNFTStaking: "0x7b52d94fB2F54a011D349B1511F9cc2620a0c7d1",
    MummyClubSale: "0x088A733494E3300f64d62cA864b29131D2eda78e",
    MummyClubVester: "0x6E3480D6EC39Ec1AAa70F53520F7D682fc4A6347"
  },
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    MlpManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    MLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    MMY: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_MMY: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_MMY: "0x35247165119B69A40edD5304969560D0ef486921",
    USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_MMY_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedMmyTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusMmyTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeMmyTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedMlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeMlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedMmyDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedMlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    MmyVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    MlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapMmyEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
  },
  
  [FANTOM]: {
    // fantom
    Vault: "0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
    Router: "0x41cD8CaFc24A771031B9eB9C57cFC94D86045eB6",
    VaultReader: "0x766257B7cE3a5E3Ad5876200Ab1671D31289BC75",
    Reader: "0xB2a477C6BA5E96f6dECbCEd836cB7d3d32ef9ecD",
    MlpManager: "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b",
    RewardRouter: "0x7b9e962dd8AeD0Db9A1D8a2D7A962ad8b871Ce4F",
    RewardReader: "0x4C4d81aA29fBb7Fe90172ad70a72F00Fb1ee46c6",
    NATIVE_TOKEN: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    MLP: "0x0ce61aAF89500e4f007884fBbf62642618dEf5dd",
    MMY: "0x01e77288b38b416F972428d562454fb329350bAc",
    ES_MMY: "0xe41c6c006De9147FC4c84b20cDFBFC679667343F",
    BN_MMY: "0x0d8393CEa30df4fAFA7f00f333A62DeE451935C1",
    USDG: "0xcab2c0a41556149330f4223c9b76d93c610dafe6",
    ES_MMY_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedMmyTracker: "0x727dB8FA7861340d49d13ea78321D0C9a1a79cd5",
    BonusMmyTracker: "0x04f23404553fcc388Ec73110A0206Dd2E76a6d95",
    FeeMmyTracker: "0xe149164D8eca659E8912DbDEC35E3f7E71Fb5789",
    StakedMlpTracker: "0xFfB69477FeE0DAEB64E7dE89B57846aFa990e99C",
    FeeMlpTracker: "0x7B26207457A9F8fF4fd21A7A0434066935f1D8E7",

    StakedMmyDistributor: "0xC2C5968e16ec9fABc39e27f9AbFC07C8Cfba6F16",
    StakedMlpDistributor: "0xa0207802B0953b17421bf9Cb10B3ACf5823496ab",

    MmyVester: "0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a",
    MlpVester: "0x2A3E489F713ab6F652aF930555b5bb3422711ac1",

    OrderBook: "0x9032aeD8C1F2139E04C1AD6D9F75bdF1D6e5CF5c",
    OrderExecutor: "0x9032aeD8C1F2139E04C1AD6D9F75bdF1D6e5CF5c",
    OrderBookReader: "0x2Aef1BC2f13118009DF8663705F736F77EFaFD5c",

    PositionRouter: "0x2D270f66feE6Ac9e27Ff6551AF5a8cFB5C8a7493",
    PositionManager: "0x68d1CA32Aee9a73534429D8376743Bf222ff1870",

    UniswapMmyFtmPool: "0x855B2EeDA3ffFEabf03b6DbF2609904da2247Cc8",
    UniswapMmyUsdcPool: "0x2a6538a456650cd454dcd8f0b4665183dba0bb27",
    ReferralStorage: "0x28cfD8e1E83beC8EE879d21634e7DC3D93102CED",
    ReferralReader: "0xc592b3ADd24ea30c030978e5E7F5d02E123b4263",

    TimeLock: "0x8ad880a11172235c6800ae882cd699ffe0172fba",
    LiquidityLock: "0xcd6Fc0f32Eee391Ec4685c6c2E5c20A19960A804",

    MummyClubNFT: "0x573235B8e2708C39DD2Fa9Ec2d880121f4F83cA9",
    MummyClubNFTStaking: "0xD8e6AF2bE7A928F075a02a32eb65F2B7DfB696ad",
    MummyClubSale: "0x6983249cFBE49eBD2c7fD37d392d69F1aEbE2d11",
    MummyClubVester: "0xa1a65D3639A1EFbFB18C82003330a4b1FB620C5a"
  }
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
