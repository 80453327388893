import React from "react";

import useSWR from "swr";

import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
} from "lib/legacy";

import Vault from "abis/Vault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import MlpManager from "abis/MlpManager.json";

import { useWeb3React } from "@web3-react/core";

import { useMmyPrice } from "domain/legacy";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { formatKeyAmount } from "lib/numbers";

import { useTotalMmySupply } from "domain/legacy";

export default function APRLabel({ chainId, label }) {
  let { active } = useWeb3React();

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const mmyAddress = getContract(chainId, "MMY");
  const esMmyAddress = getContract(chainId, "ES_MMY");
  const bnMmyAddress = getContract(chainId, "BN_MMY");
  const mlpAddress = getContract(chainId, "MLP");

  const stakedMmyTrackerAddress = getContract(chainId, "StakedMmyTracker");
  const bonusMmyTrackerAddress = getContract(chainId, "BonusMmyTracker");
  const feeMmyTrackerAddress = getContract(chainId, "FeeMmyTracker");

  const stakedMlpTrackerAddress = getContract(chainId, "StakedMlpTracker");
  const feeMlpTrackerAddress = getContract(chainId, "FeeMlpTracker");

  const mlpManagerAddress = getContract(chainId, "MlpManager");

  const mmyVesterAddress = getContract(chainId, "MmyVester");
  const mlpVesterAddress = getContract(chainId, "MlpVester");

  const vesterAddresses = [mmyVesterAddress, mlpVesterAddress];

  const walletTokens = [mmyAddress, esMmyAddress, mlpAddress, stakedMmyTrackerAddress];
  const depositTokens = [
    mmyAddress,
    esMmyAddress,
    stakedMmyTrackerAddress,
    bonusMmyTrackerAddress,
    bnMmyAddress,
    mlpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedMmyTrackerAddress,
    stakedMmyTrackerAddress,
    bonusMmyTrackerAddress,
    feeMmyTrackerAddress,
    feeMmyTrackerAddress,
    feeMlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedMmyTrackerAddress,
    bonusMmyTrackerAddress,
    feeMmyTrackerAddress,
    stakedMlpTrackerAddress,
    feeMlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [`StakeV2:walletBalances:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedMmySupply } = useSWR(
    [`StakeV2:stakedMmySupply:${active}`, chainId, mmyAddress, "balanceOf", stakedMmyTrackerAddress],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, mlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(undefined, MlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [vesterAddresses]),
    }
  );

  const { mmyPrice } = useMmyPrice(chainId, {}, active);

  const { total: mmySupply } = useTotalMmySupply(chainId);

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedMmySupply,
    mmyPrice,
    mmySupply
  );

  return <>{`${formatKeyAmount(processedData, label, 2, 2, true)}%`}</>;
}
