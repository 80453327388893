import React from "react";
import Footer from "components/Footer/Footer";
import "./BuyMMY.css";
import spooky30Icon from "img/ic_spooky.svg";
import firebird30Icon from "img/firebird.png"
import openOcean30Icon from "img/ic_openocean.svg"
import kyberSwap30Icon from "img/ic_kyber.svg"

import ExternalLink from "components/ExternalLink/ExternalLink";

export default function BuyMMYV2() {
  return (
    <div className="BuyMMYMLP default-container page-layout">
      <div className="BuyMMYMLP-container">
        <div className="section-title-block">
          <div className="section-title-content">
            <div className="Page-title">
              <span>Buy MMY on Fantom Opera</span>
            </div>
            <div className="Page-description"></div>
          </div>
        </div>
        <div className="cards-row">
          <div className="card ">
            <div className="card-header">Buy MMY from a Decentralized Exchange</div>
            <div className="card-divider"></div>
            <div className="card-body">
              <div className="App-card-content">
                <div className="exchange-info-group">
                  <div className="BuyGMXGLP-description">Buy MMY from Spooky:</div>
                  <div className="buttons-group col-1">
                    <ExternalLink href={"https://spooky.fi/#/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x01e77288b38b416F972428d562454fb329350bAc"} 
                      className="link-underline btn btn-primary btn-left btn-lg">
                      <img className="btn-image" src={spooky30Icon} alt="" />
                      <span className="btn-label">Spooky</span>
                    </ExternalLink>
                  </div>
                </div>
                <div className="exchange-info-group">
                  <div className="BuyGMXGLP-description">Buy MMY using Decentralized Exchange Aggregators:</div>
                  <div className="buttons-group">
                    <ExternalLink href={"https://app.firebird.finance/swap?inputCurrency=ETH&outputCurrency=0x01e77288b38b416F972428d562454fb329350bAc"} 
                      className="link-underline btn btn-primary btn-left btn-lg">
                      <img className="btn-image" src={firebird30Icon} alt="" />
                      <span className="btn-label">Firebird</span>
                    </ExternalLink>
                    <ExternalLink href={"https://app.openocean.finance/CLASSIC#/FANTOM/USDC/MMY"} 
                      className="link-underline btn btn-primary btn-left btn-lg">
                      <img className="btn-image" src={openOcean30Icon} alt="" />
                      <span className="btn-label">OpenOcean</span>
                    </ExternalLink>
                    <ExternalLink href={"https://kyberswap.com/swap/fantom/usdc-to-mmy"} 
                      className="link-underline btn btn-primary btn-left btn-lg">
                      <img className="btn-image" src={kyberSwap30Icon} alt="" />
                      <span className="btn-label">KyberSwap</span>
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
