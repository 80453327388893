import React from "react";
import { FiX } from "react-icons/fi";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

import { HeaderLink } from "./HeaderLink";
import "./Header.css";
import { isHomeSite, getAppBaseUrl } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import logoImg from "img/logo_MMY.svg";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {

  const isHome = isHomeSite();

  return (
    <div>
      {isHome && (
        <div className="App-header-links">
          {small && (
            <div className="App-header-links-header">
              <Link className="App-header-link-main" to="/">
                <img src={logoImg} alt="MMY Logo" />
              </Link>
              <div
                className="App-header-menu-icon-block mobile-cross-menu"
                onClick={() => clickCloseIcon && clickCloseIcon()}
              >
                <FiX className="App-header-menu-icon" />
              </div>
            </div>
          )}
          <div className="App-header-link-container">
            <ExternalLink href={getAppBaseUrl()}>
              <Trans>App</Trans>
            </ExternalLink>
          </div>
          <div className="App-header-link-container">
            <ExternalLink href="https://github.com/mummy-finance">
              <Trans>Protocol</Trans>
            </ExternalLink>
          </div>
          <div className="App-header-link-container">
            <ExternalLink href="https://docs.mummy.finance/">
              <Trans>Docs</Trans>
            </ExternalLink>
          </div>
        </div>
      )}
      {!isHome && (
        <div className="App-header-links">
          {small && (
            <div className="App-header-links-header">
              <HeaderLink className="App-header-link-main" isHomeLink
                to="/"
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
              >
                <img src={logoImg} alt="MMY Logo" />
              </HeaderLink>
              <div
                className="App-header-menu-icon-block mobile-cross-menu"
                onClick={() => clickCloseIcon && clickCloseIcon()}
              >
                <FiX className="App-header-menu-icon" />
              </div>
            </div>
          )}
          <div className="App-header-link-container">
            <HeaderLink
              to="/dashboard"
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            >
              <Trans>Dashboard</Trans>
            </HeaderLink>
          </div>
          <div className="header-dropdown">
            <div className="dropbtn">
              <HeaderLink to="/nft" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal} data-bs-toggle="dropdown">NFT</HeaderLink>
            </div>
            <div className="header-dropdown-content">
              <HeaderLink to="/nft" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
                <Trans>Mint</Trans>
              </HeaderLink>
              <HeaderLink to="/stake-nft" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
                <Trans>Stake NFT</Trans>
              </HeaderLink>
            </div>
          </div>
          <div className="App-header-link-container">
            <HeaderLink to="/earn" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
              <Trans>Earn</Trans>
            </HeaderLink>
          </div>
          <div className="App-header-link-container">
            <HeaderLink to="/buy" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
              <Trans>Buy</Trans>
            </HeaderLink>
          </div>
          <div className="App-header-link-container">
            <HeaderLink
              to="/referrals"
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            >
              <Trans>Referrals</Trans>
            </HeaderLink>
          </div>
          <div className="App-header-link-container">
            <ExternalLink href="https://leaderboard.mummy.finance/fantom/leaderboard">
              <Trans>Leaderboard</Trans>
            </ExternalLink>
          </div>
          <div className="App-header-link-container">
            <HeaderLink
              to="/poll"
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            >
              <Trans>PoLL</Trans>
            </HeaderLink>
          </div>
          <div className="App-header-link-container">
            <ExternalLink href="https://docs.mummy.finance/">
              <Trans>About</Trans>
            </ExternalLink>
          </div>
          {small && !isHomeSite() && (
            <div className="App-header-link-container">
              {/* eslint-disable-next-line */}
              <a href="#" onClick={openSettings}>
                <Trans>Settings</Trans>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
